.toast-holder {
  position: fixed;
  display: flex;
  flex-direction: row-reverse;
  padding: 16px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background-color: white;
  bottom: 0;
  opacity: 0;
  border-radius: 8px;
  overflow: hidden;
  right: 24px;
  transform: translateY(100%);
  z-index: 111;
  width: auto;
  max-width: 90%;
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}
.toast-holder.toast-success {
  background-color: #38b39f;
}
.toast-holder.toast-warning {
  background-color: #e7b257;
}
.toast-holder.toast-error {
  background-color: #db5757;
}

.toast-holder .toast {
  display: flex;
  justify-content: center;
  color: black;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  visibility: hidden;
}

.toast-holder .toast.show {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.toast-holder.show {
  bottom: 0px;
  opacity: 1;
  transform: translateY(-100%);
}
